import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <footer
      id="footer_1-0"
      className="comp footer"
      role="contentinfo"
      data-tracking-container="true"
    >
      {" "}
      <div className="l-container">
        <div className="footer__inner js-signup-container">
          <div className="footer__logo">
            <Link
              id="linked-logo_1-0"
              className="comp linked-logo mntl-block"
              to="/"
              onClick={toTop}
              aria-label="Visit MyDomaine's homepage"
            >
              <img className="icon logo " alt="" src="/head/foot4.png" />
            </Link>{" "}
          </div>
          <div className="footer__wrap">
            <div
              id="footer__newsletter-widget_1-0"
              className="comp footer__newsletter-widget newsletter-widget"
            >
              <div
                id="newsletter-widget__heading_1-0"
                className="comp newsletter-widget__heading mntl-text-block"
              >
                Email:service@mydomaine.xyz
              </div>
              <div
                id="newsletter-widget__heading_1-0"
                className="comp newsletter-widget__heading mntl-text-block"
              >
                Name:Big Horn Baseball Club
              </div>
              <div
                id="newsletter-widget__heading_1-0"
                className="comp newsletter-widget__heading mntl-text-block"
              >
                Address:2 Cardiff Ct. Sheridan, WY 82801
              </div>
              <div
                id="newsletter-widget__heading_1-0"
                className="comp newsletter-widget__heading mntl-text-block"
              >
                We send good emails.
              </div>
              <a
                href="https://www.mydomaine.com/mydomaine-newsletter-opt-in-footer-7096135"
                rel="noopener nocaes"
                id="newsletter-widget__button--desktop_1-0"
                className="newsletter-widget__button--desktop mntl-text-link "
                data-tracking-container="true"
              >
                <span className="link__wrapper">Subscribe</span>
              </a>
            </div>
          </div>
          <div className="footer__menus">
            <div id="mntl-block_1-0" className="comp mntl-block">
              <ul
                id="horizontal-nav--footer_1-0"
                className="comp horizontal-nav--footer horizontal-nav mntl-block"
                role="list"
                data-tracking-container="true"
              >
                <li
                  id="horizontal-nav__item_1-0"
                  className="comp horizontal-nav__item mntl-block"
                >
                  <Link
                    to="/about"
                    onClick={toTop}
                    id="horizontal-nav__link_1-0"
                    className="horizontal-nav__link mntl-text-link horizontal-nav__link"
                  >
                    <span className="link__wrapper">About Us</span>
                  </Link>
                </li>
                <li
                  id="horizontal-nav__item_2-0"
                  className="comp horizontal-nav__item mntl-block"
                >
                  <Link
                    onClick={toTop}
                    to="/advertise"
                    id="horizontal-nav__link_2-0"
                    className="horizontal-nav__link mntl-text-link horizontal-nav__link"
                    data-tracking-container="true"
                  >
                    <span className="link__wrapper">Advertise</span>
                  </Link>
                </li>
                <li
                  id="horizontal-nav__item_3-0"
                  className="comp horizontal-nav__item mntl-block"
                >
                  <Link
                    onClick={toTop}
                    to="/terms"
                    id="horizontal-nav__link_3-0"
                    className="horizontal-nav__link mntl-text-link horizontal-nav__link"
                    data-tracking-container="true"
                  >
                    <span className="link__wrapper">Terms of Service</span>
                  </Link>
                </li>

                <li
                  id="horizontal-nav__item_6-0"
                  className="comp horizontal-nav__item mntl-block"
                >
                  <Link
                    onClick={toTop}
                    to="/privacy"
                    id="horizontal-nav__link_6-0"
                    className="horizontal-nav__link mntl-text-link horizontal-nav__link"
                    data-tracking-container="true"
                  >
                    <span className="link__wrapper">Privacy Policy</span>
                  </Link>
                </li>
                <li
                  id="horizontal-nav__item_7-0"
                  className="comp horizontal-nav__item mntl-block"
                >
                  <Link
                    onClick={toTop}
                    to="/contact"
                    rel="nocaes"
                    id="horizontal-nav__link_7-0"
                    className="horizontal-nav__link mntl-text-link horizontal-nav__link"
                    data-tracking-container="true"
                  >
                    <span className="link__wrapper">Contact</span>
                  </Link>
                </li>

                <li
                  id="horizontal-nav__truste-badge_1-0"
                  className="comp horizontal-nav__truste-badge mntl-block horizontal-nav__item"
                >
                  <a
                    id="truste-badge_1-0"
                    className="comp truste-badge mntl-truste-badge mntl-block"
                    href="//privacy.truste.com/privacy-seal/validation?rid=f3de3b03-24ed-446d-a4a0-2dcfe4d2a2e1"
                    data-tracking-container="true"
                    aria-label="Access TRUSTe's Enterprise Privacy Certification program"
                    rel="noopener"
                  >
                    <img
                      id="badge-image_1-0"
                      className="comp badge-image mntl-block lazyloadwait lazyloaded"
                      data-src="//privacy-policy.truste.com/privacy-seal/seal?rid=f3de3b03-24ed-446d-a4a0-2dcfe4d2a2e1"
                      alt="TRUSTe"
                      width={142}
                      height={49}
                      src="//privacy-policy.truste.com/privacy-seal/seal?rid=f3de3b03-24ed-446d-a4a0-2dcfe4d2a2e1"
                    />
                  </a>
                </li>
              </ul>
            </div>{" "}
          </div>
        </div>
      </div>
      <div
        id="mntl-dotdash-universal-nav_1-0"
        className="comp mntl-dotdash-universal-nav"
        data-tracking-container="true"
      >
        {" "}
        <div className="mntl-dotdash-universal-nav__content">
          <img
            className="icon mntl-dotdash-universal-nav__logo "
            alt=""
            src="/head/foot3.png"
          />
          <div className="mntl-dotdash-universal-nav__wrapper">
            <div className="mntl-dotdash-universal-nav__text">
              MyDomaine is part of the{" "}
              <a
                href="https://www.dotdashmeredith.com"
                className="mntl-dotdash-universal-nav__text--link"
              >
                Dotdash Meredith
              </a>
              &nbsp;publishing&nbsp;family.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
