import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import "../css/mainTwo.css";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="mainBox">
        <div className="main1_box">
          <div className="main1_box_inner">
            <div
              className="main1_box_inner_top"
              data-taxonomy="Home Trends"
              data-secondary-taxonomy
              data-updated="11/02/21"
            >
              <span class="hero-card__square-divider card__square-divider"></span>
            </div>
            <p class="hero-card__title card__title">
              6 Ways to Make Your Home Less Gloomy This Winter
            </p>
            <p
              class="main1_box_inner_bot"
              data-description="These tips will guarantee a better, brighter space."
            ></p>
          </div>
        </div>

        <div className="main2_box">
          <h2>Today's Top Stories</h2>
          <ul>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/4cRt0QufK3YZYPbz6mIHE78oJxg=/380x251/filters:no_upscale():strip_icc():format(webp)/paul-hanaoka-C68oxGG81aE-unsplash-281e8812c5b34889a1ed9f34fd64eb27.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/4cRt0QufK3YZYPbz6mIHE78oJxg=/380x251/filters:no_upscale():strip_icc():format(webp)/paul-hanaoka-C68oxGG81aE-unsplash-281e8812c5b34889a1ed9f34fd64eb27.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Cleaning"
                    data-secondary-taxonomy=""
                    data-updated="11/11/21"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    13 Clever Things You Can Do With Fireplace Ashes
                  </p>
                  <p
                    class="card__description"
                    data-description="Don't toss those ashes just yet."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/oe2tn6N3AR0yaGXU3KuaSFpLfo8=/380x251/filters:no_upscale():strip_icc():format(webp)/Stocksy_txpd4662407zhY300_Medium_3085843-ea1b1650d77a471292b9989b97f67783.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/oe2tn6N3AR0yaGXU3KuaSFpLfo8=/380x251/filters:no_upscale():strip_icc():format(webp)/Stocksy_txpd4662407zhY300_Medium_3085843-ea1b1650d77a471292b9989b97f67783.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Cleaning"
                    data-secondary-taxonomy=""
                    data-updated="11/15/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    Should You Clean Hardest Before or After Entertaining?
                  </p>
                  <p
                    class="card__description"
                    data-description="We finally have your answer."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/4cRt0QufK3YZYPbz6mIHE78oJxg=/380x251/filters:no_upscale():strip_icc():format(webp)/paul-hanaoka-C68oxGG81aE-unsplash-281e8812c5b34889a1ed9f34fd64eb27.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/4cRt0QufK3YZYPbz6mIHE78oJxg=/380x251/filters:no_upscale():strip_icc():format(webp)/paul-hanaoka-C68oxGG81aE-unsplash-281e8812c5b34889a1ed9f34fd64eb27.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Home Décor"
                    data-secondary-taxonomy=""
                    data-updated="04/23/24"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    10 Feng Shui Rules for Mirrors, According to Experts
                  </p>
                  <p
                    class="card__description"
                    data-description="Mirror, mirror, on the wall."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/qpZQHWr6AMcfD04F2FH_jbQFOSI=/380x251/filters:no_upscale():strip_icc():format(webp)/Design_CathieHongInteriorsPhoto_ChristyQPhotography-1f0a7254eb7a435aa80046b0bf60d33b.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/qpZQHWr6AMcfD04F2FH_jbQFOSI=/380x251/filters:no_upscale():strip_icc():format(webp)/Design_CathieHongInteriorsPhoto_ChristyQPhotography-1f0a7254eb7a435aa80046b0bf60d33b.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="DIY Projects"
                    data-secondary-taxonomy=""
                    data-updated="08/24/21"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    30 Chic IKEA Hacks to Try for a Unique Home
                  </p>
                  <p
                    class="card__description"
                    data-description="Time to flex those DIY muscles"
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/3TQxExT1_dsSKm0wKitWUKFDCNM=/380x251/filters:no_upscale():strip_icc():format(webp)/cdn.cliqueinc.com__cache__posts__88031__ikea-hacks-88031-1523910719145-main.700x0c-9614f6c1cca846cdabb097b799a35fba.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/3TQxExT1_dsSKm0wKitWUKFDCNM=/380x251/filters:no_upscale():strip_icc():format(webp)/cdn.cliqueinc.com__cache__posts__88031__ikea-hacks-88031-1523910719145-main.700x0c-9614f6c1cca846cdabb097b799a35fba.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Paint &amp; Color"
                    data-secondary-taxonomy=""
                    data-updated="04/20/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    How to Choose a Paint Color for an Open Floor Plan,
                    According to Designers
                  </p>
                  <p
                    class="card__description"
                    data-description="Be open to color."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/Ca4F4ftT-ecdEBLLCnIvyMGH_Ns=/380x251/filters:no_upscale():strip_icc():format(webp)/ScreenShot2021-03-06at1.55.38PM-d147e4a7e67745978cd7c2dba75fdd25-b6431c0dce374f469e2c4393be6345e1.png"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/Ca4F4ftT-ecdEBLLCnIvyMGH_Ns=/380x251/filters:no_upscale():strip_icc():format(webp)/ScreenShot2021-03-06at1.55.38PM-d147e4a7e67745978cd7c2dba75fdd25-b6431c0dce374f469e2c4393be6345e1.png"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Interior Decorating"
                    data-secondary-taxonomy=""
                    data-updated="12/07/21"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    8 Spaces That Prove Decorating With Plants Will Never Go Out
                    of Style
                  </p>
                  <p
                    class="card__description"
                    data-description="Adding greenery to your home is an easy way to bring the outdoors in"
                  ></p>
                </div>
              </Link>
            </li>
          </ul>
        </div>

        <div className="main2_box">
          <h2>Design & Décor</h2>
          <ul>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/rM8i9Tuz3m0M7LZgyXR-pcPIPYo=/380x251/filters:no_upscale():strip_icc():format(webp)/decorating-tips-1-katie-hodges-design-midwilshire-1-cc8e4ad1f4ff4aae8205f2106e956b42.png"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/rM8i9Tuz3m0M7LZgyXR-pcPIPYo=/380x251/filters:no_upscale():strip_icc():format(webp)/decorating-tips-1-katie-hodges-design-midwilshire-1-cc8e4ad1f4ff4aae8205f2106e956b42.png"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Interior Decorating"
                    data-secondary-taxonomy=""
                    data-updated="06/14/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    52 Decorating Tips Every Design Enthusiast Should Know
                  </p>
                  <p
                    class="card__description"
                    data-description="The best advice designers have to offer."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/dnNFb7gfSaMXCOEzLZiswaiOWs8=/380x251/filters:no_upscale():strip_icc():format(webp)/139627146_869080167262046_4319134424535294943_n-5e85776022824f9ebbead54769fbd081.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/dnNFb7gfSaMXCOEzLZiswaiOWs8=/380x251/filters:no_upscale():strip_icc():format(webp)/139627146_869080167262046_4319134424535294943_n-5e85776022824f9ebbead54769fbd081.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Houseplants"
                    data-secondary-taxonomy=""
                    data-updated="08/26/21"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    The Norfolk Pine Is Perfect in Your Home Year-Round: Here's
                    How to Care for It
                  </p>
                  <p
                    class="card__description"
                    data-description="For those who just love the smell of a Christmas tree."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/1w6ENGH0oK27UXsA-uTYy0Z_7IE=/380x251/filters:no_upscale():strip_icc():format(webp)/279269849_142285611690836_438158991230331842_n-35d97b62a7624e5ebadadb7f62303885.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/1w6ENGH0oK27UXsA-uTYy0Z_7IE=/380x251/filters:no_upscale():strip_icc():format(webp)/279269849_142285611690836_438158991230331842_n-35d97b62a7624e5ebadadb7f62303885.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Home Décor"
                    data-secondary-taxonomy=""
                    data-updated="05/02/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    10 Cottagecore Decorating Ideas That Are Perfectly Cozy
                  </p>
                  <p
                    class="card__description"
                    data-description="Say yes to florals and whimsy. "
                  ></p>
                </div>
              </Link>
            </li>
          </ul>
        </div>

        <div className="main3_box">
          <div className="main3_box_left">
            <div className="main3_box_left_top">
              <div
                id="subscription-island__icon_1-0"
                class="main3_box_left_top_svg"
              ></div>
              <div
                id="subscription-island__heading_1-0"
                class="main3_box_left_top_main1"
              >
                We send good emails.
              </div>
              <div
                id="subscription-island__subheading_1-0"
                class="main3_box_left_top_main2"
              >
                Subscribe now.
              </div>
            </div>{" "}
            <a href="/" rel="noopener nocaes" class="main3_box_left_top_link ">
              <span class="link__wrapper">Subscribe</span>
            </a>
          </div>

          <div className="main3_box_right">
            <div
              id="subscription-island__social-follow-header_1-0"
              class="main3_box_right_title"
            >
              Tap-tap. Pin. Like. Repeat.
            </div>
            <div className="main3_box_right_svg">
              <ul>
                <img alt="" src="/head/svg1.png" />
              </ul>
            </div>
          </div>
        </div>

        <div className="main2_box">
          <h2>Room Ideas</h2>
          <ul>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/GIcJjnhrFkKJUveKvnXMEdICmYo=/380x251/filters:no_upscale():strip_icc():format(webp)/StudioMcGee_RiverbottomsRemodel-2344567735194cbcaa492206c876b519.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/GIcJjnhrFkKJUveKvnXMEdICmYo=/380x251/filters:no_upscale():strip_icc():format(webp)/StudioMcGee_RiverbottomsRemodel-2344567735194cbcaa492206c876b519.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Entryway"
                    data-secondary-taxonomy=""
                    data-updated="03/09/21"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    20 Design Ideas to Make Your Entryway's First Impression a
                    Good One
                  </p>
                  <p
                    class="card__description"
                    data-description="Make a statement from the first step inside."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/iCu45mmDzzZLs_FN8oj1X-nbUwI=/380x251/filters:no_upscale():strip_icc():format(webp)/unique-kitchen-backsplash-ideas-10-tyler-karu-bath-historic-13691242986745cfb8d95388a9507675.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/iCu45mmDzzZLs_FN8oj1X-nbUwI=/380x251/filters:no_upscale():strip_icc():format(webp)/unique-kitchen-backsplash-ideas-10-tyler-karu-bath-historic-13691242986745cfb8d95388a9507675.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Kitchen"
                    data-secondary-taxonomy=""
                    data-updated="03/15/21"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    Move Over, Subway Tile: These 27 Kitchen Backsplash Ideas
                    Are Anything But Ordinary
                  </p>
                  <p
                    class="card__description"
                    data-description="Make your backsplash the boldest part of your home."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/3n4hJjC1eeJ1JbfIgd0jq9nEedk=/380x251/filters:no_upscale():strip_icc():format(webp)/image0021-672b8d9e5e404dd8a8cc01441b3a5428.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/3n4hJjC1eeJ1JbfIgd0jq9nEedk=/380x251/filters:no_upscale():strip_icc():format(webp)/image0021-672b8d9e5e404dd8a8cc01441b3a5428.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Living Room"
                    data-secondary-taxonomy=""
                    data-updated="10/06/24"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    These 20 Living Rooms Make a Strong Case for White Sofas
                  </p>
                  <p
                    class="card__description"
                    data-description="These white couch ideas are next level."
                  ></p>
                </div>
              </Link>
            </li>
          </ul>
        </div>

        <div className="main2_box">
          <h2>Life at Home</h2>
          <ul>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/-NQgeOrsx78PFtSUD1ljx3YpkoE=/380x251/filters:no_upscale():strip_icc():format(webp)/GettyImages-1317231931-b25c2980d5884bcdb8e4891e888e0783.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/-NQgeOrsx78PFtSUD1ljx3YpkoE=/380x251/filters:no_upscale():strip_icc():format(webp)/GettyImages-1317231931-b25c2980d5884bcdb8e4891e888e0783.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Cleaning"
                    data-secondary-taxonomy=""
                    data-updated="04/06/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    How to Clean Gold Jewelry and Décor and Keep It Shining
                  </p>
                  <p
                    class="card__description"
                    data-description="Get your gold looking good-as-new."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/dKzUnchINjgUYAmjsnyoY1xhgsA=/380x251/filters:no_upscale():strip_icc():format(webp)/friendsgiving7-e14a77c87d714a829c03ab7b286f48e2.jpeg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/dKzUnchINjgUYAmjsnyoY1xhgsA=/380x251/filters:no_upscale():strip_icc():format(webp)/friendsgiving7-e14a77c87d714a829c03ab7b286f48e2.jpeg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Entertaining"
                    data-secondary-taxonomy=""
                    data-updated="11/15/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    These 25 Stunning Tablescapes Will Work All Year Long
                  </p>
                  <p
                    class="card__description"
                    data-description="Time to get hosting."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/RCSovkppBYcaCC9Z6h5VoRgf2XY=/380x251/filters:no_upscale():strip_icc():format(webp)/BeautyIsAbundantforMyDomaine2-56f21c07b6d64c63999d6afeee4f88c7.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/RCSovkppBYcaCC9Z6h5VoRgf2XY=/380x251/filters:no_upscale():strip_icc():format(webp)/BeautyIsAbundantforMyDomaine2-56f21c07b6d64c63999d6afeee4f88c7.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Home Trends"
                    data-secondary-taxonomy=""
                    data-updated="08/31/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    8 Designer-Approved Ways to Keep Family Clutter at Bay
                  </p>
                  <p
                    class="card__description"
                    data-description="Proof that home organization can be chic."
                  ></p>
                </div>
              </Link>
            </li>
          </ul>
        </div>

        <div className="main2_box">
          <h2>News</h2>
          <ul>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/qOMUFlJ5Z60a1rUB_RTNMG4bhjk=/380x251/filters:no_upscale():strip_icc():format(webp)/LaMer1-56e92041f4894bf88a8b59c364d00eef.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/qOMUFlJ5Z60a1rUB_RTNMG4bhjk=/380x251/filters:no_upscale():strip_icc():format(webp)/LaMer1-56e92041f4894bf88a8b59c364d00eef.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Home Trends"
                    data-secondary-taxonomy=""
                    data-updated="01/19/23"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    38 Steal-Worthy Design Ideas I Found at Hotels
                  </p>
                  <p
                    class="card__description"
                    data-description="Get inspired by these stylish hotels."
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/9daZp0E9qAC09QXYekfOW-lmqfI=/380x251/filters:no_upscale():strip_icc():format(webp)/40_Virginia_Project_Bonus_Rm_Well_by_Design_Web_Res-1a81baa8fde54510b09ad88ad685acde.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/9daZp0E9qAC09QXYekfOW-lmqfI=/380x251/filters:no_upscale():strip_icc():format(webp)/40_Virginia_Project_Bonus_Rm_Well_by_Design_Web_Res-1a81baa8fde54510b09ad88ad685acde.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Home Trends"
                    data-secondary-taxonomy=""
                    data-updated="06/10/23"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    5 Designers on Their Earliest Design Memories
                  </p>
                  <p
                    class="card__description"
                    data-description="And how they still inform their aesthetics today. "
                  ></p>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/">
                <div className="card__media ">
                  <div
                    className="img-placeholder"
                    style={{ paddingBottom: "66.0%" }}
                  >
                    <img
                      data-src="https://www.mydomaine.com/thmb/zqs-YYEqW8tVTmYx2raP8IBfUCM=/380x251/filters:no_upscale():strip_icc():format(webp)/bobbyberk_recirc-a7ce15ea75f148358c909f3463c64023.jpg"
                      width={380}
                      height={251}
                      alt="Fireplace"
                      className="card__image universal-image__image lazyloadwait ls-is-cached lazyloaded"
                      src="https://www.mydomaine.com/thmb/zqs-YYEqW8tVTmYx2raP8IBfUCM=/380x251/filters:no_upscale():strip_icc():format(webp)/bobbyberk_recirc-a7ce15ea75f148358c909f3463c64023.jpg"
                    />
                  </div>
                </div>
                <div class="card__content">
                  <div
                    class="card__meta"
                    data-taxonomy="Home Trends"
                    data-secondary-taxonomy=""
                    data-updated="12/12/22"
                  >
                    <span class="card__square-divider"></span>
                  </div>
                  <p class="card__title">
                    Bobby Berk Completed This Amazing Kitchen Reno in Just One
                    Month
                  </p>
                  <p
                    class="card__description"
                    data-description="See the change for yourself."
                  ></p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
