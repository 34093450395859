import "../css/header.css";
import React from "react";
import { Link } from "react-router-dom";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="head_left">
        <Link to="/">
          <img alt="" src="/head/head.png" />
        </Link>
      </div>

      <div className="head_right">
        <ul>
          <li>
            <Link to="/" onClick={toTop}>
              Home
            </Link>
          </li>{" "}
          <li>
            <Link to="/design" onClick={toTop}>
              Design
            </Link>
          </li>
          <li>
            <Link to="/room" onClick={toTop}>
              Romm ideas
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={toTop}>
              about us
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={toTop}>
              contact us
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;
